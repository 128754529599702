import * as React from 'react';
import { Helmet } from 'react-helmet';
import useSiteMetadata from '../hooks/SiteMetadata';
import { withPrefix } from 'gatsby';

const TemplateWrapper = ({ children }) => {
  const { title, description } = useSiteMetadata();
  return (
    <div>
      <Helmet>
        <html lang='en' />
        <title>{title}</title>
        <meta name='description' description={description} />
        <meta name='theme-color' content='#fff' />
        <meta property='og:type' content='business.business' />
        <meta property='og:title' content={title} />
        <meta property='og:description' content={description} />
        <meta property='og:url' content='/' />
        <meta
          property='og:image'
          content={`${withPrefix('/')}img/logo.png`}
        />
      </Helmet>
      <div>{children}</div>
    </div>
  );
};

export default TemplateWrapper;
